<!--能源模块--设备页-->
<template>
  <div id="equipment" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="header">
      <div class="header-left">
         <div class="header-condition">
            <!-- <span> 设备类型：</span> -->
            <span> {{$t('distribution.a27')}}：</span>
            <a-select v-model="deviceType" style="width: 240px;">
              <a-select-option
                  v-for="(itme, index) in iconList"
                  :key="index"
                  :value="itme.no"
                >
                  {{ itme.text }}
                </a-select-option>
            </a-select>
        </div>
        <div class="header-condition">
            <!-- <span> 设备所属：</span> -->
            <span> {{$t('energy.enedata473')}}：</span>
            <!-- <a-tree-select
                v-model="parentId"
                style="width: 240px;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="pointGrs"
                placeholder="请选择设备所属"
                tree-default-expand-all
                :replace-fields="{children:'children', title:'text', key:'no', value: 'no' }"
            /> -->
            <a-cascader  style="width: 240px;"
                v-model="grs" 
                :field-names="{ label: 'text', value: 'no', children: 'children' }" :options="pointGrs" 
                change-on-select   />
              
        </div>
        <div class="header-condition">
          <span>{{ $t('energy.setting.reportGr.kikiName') }}：</span>
          <a-input
            v-model="deviceName"
            :placeholder="namePlaceholder"
            style="width: 150px"
          />
        </div>
      </div>
     
      <div>
        <a-button type="primary" style="width: 120px" @click="search"> {{ $t('energy.enedata006') }} </a-button>
      </div>
    </div>
    <div class="emain">
      <div class="emain_content">
        <my-card
          v-for="item in cardlist"
          :key="item.id"
          :cardContent="item"
          @openDetailDialog="openDetailDialog"
        >
        </my-card>
        <div class="empty" v-for="n in 10" :key="'m' + n"></div>
      </div>
    </div>
    <div class="footer">
      <ul class="express">
        <li class="expressli" v-for="(item, index) in icon" :key="index">
          <img :src="item.url" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    
    <issueAnnounceDialog :equipmentId="equipmentId" :id="announceId" ></issueAnnounceDialog>
  </div>
</template>

<script>
import { getTenantList } from "../../api/door";
import { getCardList,equipmentTypeList,getCommonEquipments} from "../../api/energy";
import issueAnnounceDialog  from "../energy/issueAnnounceDialog";
import card from "./components/card";
export default {
  name: "equipment",
  data() {
    return {
      loading: false,
      iconList: [],
      pointGrs:[],
      parentId:"0",
      deviceType: "",
      deviceName: "",
      options: [],
      tenants: [],
      tenantId: 0,
      cardlist: [],
      icon: [
        {
          name: this.$t('doorequipment.a6'),
          url: require("../../../public/images/door/equipment01.png"),
        },
        {
          name: this.$t('doorequipment.a7'),
          url: require("../../../public/images/door/equipment02.png"),
        },
        {
          name: this.$t('doorequipment.a8'),
          url: require("../../../public/images/door/equipment03.png"),
        },
        {
          name: this.$t('doorequipment.a9'),
          url: require("../../../public/images/door/equipment04.png"),
        },
        {
          name:this.$t('doorequipment.a10'),
          url: require("../../../public/images/door/equipment05.png"),
        },
        {
          name: this.$t('doorequipment.a11'),
          url: require("../../../public/images/door/equipment06.png"),
        },
      ],
      namePlaceholder:'',
      mkind:undefined,
      serviceType:22,
      grs:[''],
      issueAnnounceDialogVisible:false,
      equipmentId:0,
      announceId:0,
    };
  },
  
  mounted(){
    
  },
  async activated() {
   
    this.namePlaceholder = this.$t('energy.setting.reportGr.inputLabel').replace('${label}',this.$t('energy.setting.reportGr.kikiName'));
    this.getSearchParam();
    // this.getTenantList();
    this.search();     
  },
  methods:{
    getSearchParam(){
      let data = {
          action:0,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: 0,
          tenantId: 0,
      };
      console.log("equipmentTypeList para",data);
      getCommonEquipments(data)
      .then((res) => {
          let { data } = res;
          this.iconList = [{no: "", text: this.$t("energy.enedata472")},...data.iconList];
          this.pointGrs = [{no: "", text: this.$t("energy.enedata472")},...data.pointGrs];
      })
      .catch((err) => {
          console.log(err);
      });
    },
    getTenantList(){
      this.tenants = [];
      getTenantList(this.$route.query.id)
      .then((res) => {
        let { data } = res;
        for (let i = data.length - 1; i > -1; i--) {
          this.tenants.push({ value: data[i].id, name: data[i].name });
          this.tenantId = this.tenants[0].value;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    showList(){
      this.$router.push({
        path: "/homepage/energy/equipmentList",
        query: { id: this.$route.query.id},
      });
    },
    search(){
        let siteId = 0;
        let parentId =0;
        let action = 5;
        if(this.grs.length>0 && this.grs[0]!==''){
            let val = this.grs[this.grs.length-1];
            if(val.indexOf('site')>=0){
                let vals = val.split(":");
                siteId = vals[1];
                action = 1;
            } else {
                parentId = val;
                let vals = this.grs[0].split(":");
                if(vals.length>1){
                  siteId = vals[1];
                  action = 1;
                }
            }
        }
        
        let params = {
          action:action,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: siteId,
          tenantId: this.tenantId,           
          name:this.deviceName,
          parentId: parentId,
          iconNo: this.deviceType,
          serviceType:this.serviceType,            
          pageNum :0,
          pageSize : 0
        };
        this.loading = true;
        getCommonEquipments(params)
          .then((res) => {
              let { data } = res;
              this.cardlist = data.virtualEquipmentList;
              console.log(res);
              console.log(this.cardlist)
              this.loading = false;
          })
          .catch((err) => {
              this.loading = false;
              console.log(err);
          });
    },
    openDetailDialog(record){
      if(record.displayDevice===3){
        this.equipmentId = record.id;
        this.announceId = record.announceId;
        this.issueAnnounceDialogVisible = true;
      }
    },
  },
  components: {
    "my-card": card,
    issueAnnounceDialog,
  },
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
#equipment {
  height: 100%;
}
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  overflow: hidden;
}
.header-left {
  display: flex;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}
.express {
  display: flex;
}
.expressli {
  display: flex;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
}
.expressli img {
  margin-right: 5px;
}
.emain {
  width: 100%;
  height: calc(100% - 90px);
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.emain_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
/* .emain::after{
  clear: both;
} */
.empty {
  width: 200px;
  height: 1px;
  margin-bottom: 15px;
}
.footer {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
</style>