<!--信息发布--设备卡片组件-->
<template>
  <div :id="cardId" class="distribution-card" @click="goDetails" :title="cardContent.name" style="position: relative;">

    <div class="title-box">
        <img :src="getImages" />
        <div class="title" :title="cardContent.name">{{ cardContent.name }}</div>
    </div>

    <div  style="position: relative;">
        <div v-if="cardContent.firDisPointId>0" :title="cardContent.firDisPointName">
            <div v-if="cardContent.firDisPointType==3 || cardContent.firDisPointType==4 || cardContent.firDisPointType==5" >
                <span class="pv">{{ cardContent.firDisPointText }}</span>
            </div>
            <div v-else-if="cardContent.firDisPointType==13 || cardContent.firDisPointType==14 || cardContent.firDisPointType==19">
                <span class="pv">{{ cardContent.firDisPointText }}</span>
            </div>
            <div v-else>
                <span class="pv">{{ cardContent.firDisPointPv }}</span>
                <span class="unit">{{ cardContent.firDisPointText }}</span>
            </div>

        </div>
        <div v-if="cardContent.secDisPointId>0" :title="cardContent.secDisPointName">
            <div v-if="cardContent.secDisPointType==3 || cardContent.secDisPointType==4 || cardContent.secDisPointType==5" >
                <span class="pv">{{ cardContent.secDisPointText }}</span>
            </div>
            <div v-else-if="cardContent.secDisPointType==13 || cardContent.secDisPointType==14 || cardContent.secDisPointType==19">
                <span class="pv">{{ cardContent.secDisPointText }}</span>
            </div>
            <div v-else>
                <span class="pv">{{ cardContent.secDisPointPv }}</span>
                <span class="unit">{{ cardContent.secDisPointText }}</span>
            </div>

        </div>
      </div>
      <div class="v-btn" v-if="cardContent.displayDevice === 4 || cardContent.displayDevice === 1 || cardContent.displayDevice === 2">
          <a-button type="info" icon="snippets" shape="round" size="small" @click.stop @click="gotoPage()"/>
      </div >

  </div>
</template>

<script>

export default {
  name: "energy",
  props: {
    cardContent: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      changeState: false,
    };
  },
  methods: {
    // 根据卡片类型跳转对应的详情页
    goDetails() {
      let type = this.cardContent.iconNo;
      console.log('iconNo',this.cardContent.iconNo)
      if(type === 880 || type === 890){//
        let eqmid =  this.cardContent.id;
        this.$router.push({
            path: "/homepage/distribution/equipmentDetail",
            query: {
              deviceId: eqmid,
              siteId:this.$route.query.id,
              equipmentSiteId:this.cardContent.siteId,
              iconNo:type,
            },
        });
      } else if(type === 520){//
        let eqmid =  this.cardContent.id;
        this.$router.push({
            path: "/homepage/distribution/faceEquipmentDetail",
            query: {
              deviceId: eqmid,
              siteId:this.$route.query.id,
              equipmentSiteId:this.cardContent.siteId,
              iconNo:type,
            },
        });
      } else {
        let displayDevice = this.cardContent.displayDevice;
        if(displayDevice===3){
            this.$emit('openDetailDialog', this.cardContent);
        }else{
            let eqmid =  this.cardContent.id;
            this.$router.push({
                path: "/homepage/energy/energyEquipmentDetails",
                query: {
                  id: eqmid,
                  equipmentSiteId:this.cardContent.siteId,
                  iconNo:type
                },
            });
        }
      }
    },
    gotoPage(){
      if(this.cardContent.displayDevice == 1 && this.cardContent.key){
          let url = window.location.origin+"/#/board?key="+this.cardContent.key;
          window.open(url,'_blank');
      }else if(this.cardContent.displayDevice === 2 || this.cardContent.displayDevice === 4){
          let url = window.location.origin+"/"+this.cardContent.key+"/#/";
          window.open(url,'_blank');
      }
    }
  },
  computed: {
    // 卡片图标根据对应iocnNO加载对应图标
    getImages: function () {
      let iconNo = this.cardContent.iconNo.toString();
      return require("../../../../public/images/facility/" + iconNo + ".png");
    },
    cardId() {
      return "distribution-att-" + this.cardContent.id + '-' +Math.floor((Math.random() * 10000) + 1);
    },
  },
};
</script>

<style scoped>
.distribution-card {
  width: 200px;
  height: 110px;
  background-color: #f2f4f7;
  border-radius: 3px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  cursor: pointer;
  transition: 200ms;
  float: left;
}
.distribution-card:hover {
  border: 1px solid #7682ce;
}
.title-box{
  display: flex;
  justify-content: flex-start;
}
.title{
    height: 48px;
    line-height: 48px;
    font-size: 14px;
   
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.title-name{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.pv {
  font-size: 18px;
  padding-left: 10px;
  font-family: "Microsoft YaHei";
 
}
.unit{
  font-size: 11px; 
  font-family: "Microsoft YaHei";
}
.v-btn{
  position: absolute;
  right: 2%;
  bottom: 5%;
  display: grid;
}
</style>